<template>
  <div class="card">
    <div class="card-header">
      <span style="font-weight: bold">Nuevos clientes
        <span v-if="self.formData.solicitudes">({{ self.formData.solicitudes.length }})</span>
      </span>
    </div>
    <div class="card-body" style="height: 230px; position: relative; overflow">
      <field ref="solicitudes" name="solicitudes" :structureRef="'widget_solicitudes_clientes'" widget="handsontable"
        :height="150" :width="705" :minRows="5" style="top: 5px; left: 5px;" readonly
        :htSettings="{ columnSorting: true, beforeColumnSort: null }" class="zebraStyle" :columns="[
          {
            name: 'id',
            header: 'Acciones',
            readOnly: true,
            renderer: rendererAcciones,
            columnSorting: {
              indicator: false,
              headerAction: false,
              compareFunctionFactory: () => () => 0
            }
          },
          {
            name: 'nombre',
            header: 'Nombre',
          },
          {
            name: 'codigo_postal',
            header: 'Código postal',
          },
          {
            name: 'nif_cif',
            header: 'NIF/CIF',
          },
          {
            name: 'email',
            header: 'Email',
          },
          {
            name: 'movil',
            header: 'Móvil',
          },
          {
            name: 'pais_id',
            header: 'País',
            type: 'm2o',
            primary: 'codigo',
            showCode: true,
          }
        ]" />
    </div>
  </div>
</template>
<script>
import rFormMixin from "./../components/rFormMixin.vue";
import Handsontable from "handsontable";
import Vue from "vue/dist/vue.js";
export default {
  mixins: [rFormMixin],
  props: {
    tipo: String,
  },
  data: function () {
    var self = this;
    return {
      dbAdapter: "solicitud_cliente",
      mode: "new",
    };
  },
  methods: {
    rendererAcciones: function (
      instance,
      td,
      row,
      col,
      prop,
      value,
      cellProperties
    ) {
      var self = this;
      Handsontable.renderers.BaseRenderer.apply(this, arguments);
      td.classList.add("td-buttons");
      let $td = window.$(td);
      $td.empty();
      //console.log(td, instance.getSourceData()[row]);
      if (instance.getSourceData()[row].id) {

        let html = `
        <div>
            <button class="button" v-tooltip="'Confirmar'" @click="confirmar"><fa-icon icon="check" /></button>
            <button class="button" v-tooltip="'Eliminar'" @click="eliminar"><fa-icon icon="trash" /></button>
        </div>
        `;
        var ComponentClass = Vue.extend({
          template: html,
          methods: {
            confirmar(e) {
              e.preventDefault();
              e.stopPropagation();
              let d = instance.getSourceData()[row];

              window.DB.getList("cliente", { filter: [['nif_cif', '=', d.nif_cif]], fields: ['codigo'] }).then(res => {
                res.count = parseInt(res.count)
                let initialFormData = {
                  nombre_comercial: d.nombre,
                  nombre_fiscal: d.nombre,
                  nif_cif: d.nif_cif,
                  cp_fiscal: d.codigo_postal,
                  email_fiscal: d.email,
                  email_comercial: d.email,
                  password2: d.password,
                  movil_fiscal: d.movil,
                  pais_fiscal_id: d.pais_id,
                  login: d.email,
                  activo_web: 1,
                  tipo_cliente_id: { codigo: 'T', descripcion: 'Temporal' }
                };
                self.app.openWindow("cliente", {
                  props: {
                    initialMode: res.count ? 'empty' : 'new',
                    initialId: res.count ? res.data[0].codigo : null,
                    initialFormData: res.count ? null : initialFormData,
                    defaultMode: 'edit',
                    onLoad: function () {
                      let self = this;
                      self.formData = { ...self.formData, ...{ password2: d.password, login: d.email, activo_web: 1 } }
                    },
                    onSave: (_, itemId) => {
                      window.DB.delete('solicitud_cliente', { itemId: d.id })
                      window.DB.action('cliente', 'enviarEmailSolicitudRegistroAceptada', { data: { itemId } })
                    }
                  }
                });
              })


            },
            eliminar(e) {
              let d = instance.getSourceData()[row];
              self.app.confirm(
                "¿Seguro que deseas eliminar este registro?",
                function () {
                  window.DB.delete('solicitud_cliente', { itemId: d.id, })
                    .then(function (res) {
                      self.app.toast("Registro eliminado con éxito", "success");
                      self.refrescar()
                    })
                    .catch(function (res) {
                      self.app.toast(res.error[0], "error");
                    });
                }
              );
            }
          },
        });
        var ins = new ComponentClass();
        ins.$mount();
        window.$(ins.$el).appendTo($td);
      }

      return $td[0];
    },
    refrescar() {
      var self = this;
      let filter = [];
      window.DB.getList("solicitud_cliente", {
        filter,
        fields: [
          "nombre",
          "nif_cif",
          "codigo_postal",
          "email",
          "password",
          "movil",
          { name: 'pais_id', fields: ['codigo', 'nombre'] }
        ],
        order: "create_date",
        orderDir: "ASC",
      }).then((res) => {
        self.$set(self.formData, "solicitudes", res.data);
      });
    },
  },
  mounted() {
    var self = this;

    self.refrescar();


    window.App.$on("db-save", function (params) {
      if (params.model == "solicitud_cliente") {
        self.refrescar();
      }
    });

    window.App.$on("db-delete", function (params) {
      if (params.model == "solicitud_cliente") {
        self.refrescar();
      }
    });

    window.App.$on('request-cliente', params => {
      self.refrescar();
    })
  },
};
</script>
<style>
td.estado-pendiente {
  background-color: #ffffbb !important;
  color: black !important;
}

td.estado-proceso {
  background-color: #aadaff !important;
  color: black !important;
}

td.estado-finalizado {
  background-color: #cec !important;
  color: black !important;
}

td.estado-cancelado {
  background-color: #ffbbbb !important;
  color: black !important;
}

td.estado-enviado {
  background-color: #e7d9ff !important;
  color: black !important;
}
</style>